/*
****************************************************************************************************

Stylesheet : Icons

Table of contents :

1 - Taylor & Francis icon font
2 - Google Material Design icon font

****************************************************************************************************

1 - TAYLOR & FRANCIS ICON FONT

****************************************************************************************************
*/

[class^="icon-"], [class*=" icon-"] {
  font-size: 1.875rem;
  margin-right: .625rem;
}
.icon-down {
  margin-right: 0;
}

/*
****************************************************************************************************

2 - GOOGLE MATERIAL DESIGN ICON FONT

****************************************************************************************************
*/

@include font-face($material-icons-font-path, unquote('MaterialIcons-Regular'), 1, $material-icons-font-family);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;  /* Preferred icon size 24px */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
