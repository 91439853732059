/*
****************************************************************************************************

Stylesheet : Main

Table of contents :

1 - Imports
2 - Other

****************************************************************************************************

1 - IMPORTS

****************************************************************************************************
*/

@import './styles/variables';
@import './styles/fonts';
@import './styles/icons';
@import './styles/app';

/*
****************************************************************************************************

2 - OTHER

****************************************************************************************************
*/

.submission-card-info {
    display: flex;
    background-color: rgba(161, 220, 200, 0.5);
    border-radius: 10px;
    font-size: 13px;
    text-align: left;
    width: 70%;
    margin-left: 30px;
    margin-top: 15px;
    padding-left: 0px;

    .col-left {
      border-radius: 10px 0px 0px 10px;
      background-color: rgba(75, 192, 154, 0.6);
    }
    .col-right{
      padding: 12px;
      padding-left: 20px;
    }
    .info-icon {
      margin-left: 8px;
      margin-right: 8px;
      display: block;
      width: 30px;
    }
    .small-width {
      width:8%;
    }
    a {
      text-decoration: none;
      color: #007a96;
    }
    span{
      padding-left: 5px;
    }
  }

  .mb-15 {
    margin-bottom: 15px;
  }

.action-bg {
  background-color: #f4ead8;
  .col-left {
    background-color: #f7ddad;
  }
}

.actions {
  &.btn-container {
    position: relative;
    text-align: right;
    padding-right: 26px !important;

    & > button {
      margin-right: 10px;
    }

    .contact-link-hover {
      padding-right: 10px;
      .btn-primary,
      .secondary {
        margin: 0 !important;
      }
      .copy-icon {
        background-color: #FAFAFA;
        border: 1px solid #e7e7e7;
        cursor: pointer;
        display: none;
        padding: 9px;
        position: absolute;
        top: 0;
        right: -10px;
        height: 40px;
        width: 40px;
      }
    }
    &.btn-container .contact-link-hover {
      &:hover {
        .copy-icon {
          display: inline-block;
        }
      }
    }
  }
}
  .commission-contact-link-hover {
    display: inline-flex;
    margin-right: -25px;
    text-align: left;
    width: 100%;
    .copy-icon {
      display: none;
    }
  }
  .commission-contact-link-hover:hover {
    .copy-icon {
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: 36px;
      margin-right: -40px;
      margin-left: 10px;
    }
  }


  .contact-link {
    color: #007a96;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;
    width: 125px;
    text-align: center;
  }
  .tooltip::after {
    background-color: #E7E7E7;
    border-radius: 4px;
    color: #282828;
    font-size: 12px;
    display: none;
    padding: 5px 10px;
    position: absolute;
    text-align: center;
    z-index: 999;
  }
  .closeBannerButton{
    background-color: #de1619;
    padding: 6px;
    line-height: 100%;
    color: #fff;
    font-weight: 700;
    margin-left: auto;
    border-radius: 5px;
}
  .tooltip::before {
    background-color: #E7E7E7;
    content: ' ';
    display: none;
    position: absolute;
    width: 15px;
    height: 15px;
    z-index: 999;
  }
  
  .tooltip:hover::after {
    display: block;
  }
  
  .tooltip:hover::before {
    display: block;
  }
  
  .tooltip.copy::after {
    content: 'Click to copy email';
    width:106px;
    top: 0;
    left: 0;
    transform: translate(-50%, calc(-100% - 10px));
  }
  
  .tooltip.copy::before {
    top: 0;
    left: 60%;
    transform: translate(-70%, calc(-100% - 5px)) rotate(45deg);
  }
  
  
  .tooltip.copied::after {
    content: 'Email copied';
    width:82px;
    top: 0;
    left: 0%;
    transform: translate(-50%, calc(-100% - 10px));
  }
  
  .tooltip.copied::before {
    top: 0;
    left: 60%;
    transform: translate(-70%, calc(-100% - 5px)) rotate(45deg);
  }
  .parent-card.copy::before,
  .parent-card.copied::before {
    left: 60%;
  }
  @media only screen and (max-width:641px) {
    .submission-card-info {
      margin-left: 0px;
      margin-top: 0px;
      padding-left: 0px;
      margin-bottom: 10px;
      width: 100%;
      .small-width{
        width: 15%;
      }
      .col-left {
        padding-left: 5px;
        padding-right: 5px;
      }
      .col-right {
        padding: 12px;
      }
    }
  }
  