@mixin font-face($fontpath, $fontname, $fontversion: 1, $fontfamily: arial, $fontweight: normal, $fontstyle: normal) {
  @font-face {
    font-family: $fontfamily;
    src:url('#{$fontpath}/#{$fontname}.eot?v=#{$fontversion}');
    src:url('#{$fontpath}/#{$fontname}.eot?#iefix&v=#{$fontversion}') format('embedded-opentype'),
    url('#{$fontpath}/#{$fontname}.woff?v=#{$fontversion}') format('woff'),
    url('#{$fontpath}/#{$fontname}.ttf?v=#{$fontversion}') format('truetype'),
    url('#{$fontpath}/#{$fontname}.svg##{$fontname}?v=#{$fontversion}') format('svg');
    font-weight: $fontweight;
    font-style: $fontstyle;
    font-display: swap;
  }
}

/*
****************************************************************************************************

2 - OPEN SANS

****************************************************************************************************
*/

@include font-face($open-sans-font-path, unquote('OpenSans-Regular-webfont'), 1, $open-sans-font-family, 400, normal);
@include font-face($open-sans-font-path, unquote('OpenSans-Italic-webfont'), 1, $open-sans-font-family, 400, italic);
@include font-face($open-sans-font-path, unquote('OpenSans-Bold-webfont'), 1, $open-sans-font-family, 700, normal);
@include font-face($open-sans-font-path, unquote('OpenSans-BoldItalic-webfont'), 1, $open-sans-font-family, 700, italic);

/*
****************************************************************************************************

2 - DROID

****************************************************************************************************
*/

@include font-face($droid-serif-font-path, unquote('DroidSerif-webfont'), 1, $droid-serif-font-family, 400, normal);
@include font-face($droid-serif-font-path, unquote('DroidSerif-Italic-webfont'), 1, $droid-serif-font-family, 400, italic);
@include font-face($droid-serif-font-path, unquote('DroidSerif-Bold-webfont'), 1, $droid-serif-font-family, 700, normal);
@include font-face($droid-serif-font-path, unquote('DroidSerif-BoldItalic-webfont'), 1, $droid-serif-font-family, 700, italic);

