@import '../styles/variables';
// regular style toast
@import '~ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions

html, body {
    position: relative;
    min-height: 100%;
    height: 100%;
}

body {
    background-color: $lightgrey;
    color: $mineshaft;
    font-family: $open-sans-font-family, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
}

body * {
  box-sizing: border-box;
  word-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
    height: 100%;
    width: 100%;
}

#app {
    min-height: 100%;
    position: relative;
    padding-bottom: 190px; /* Equal to footer height */
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
}

.card {
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: white;  
}

button:disabled,
button[disabled]{
  background-color: #dcdcdc;
  border: 1px solid #dcdcdc;
  color: #525050;
}

button {
  border-radius: 0px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  background-color: $tandf;
  color: white;
  border: 1px solid #007a96;
  outline: 0;

  &:hover:not([disabled]) {
    box-shadow: inset -2px -2px 0 0 #0088a3;
    background-color: #007a96;
    
  }
  &:hover:disabled {
   cursor: not-allowed;
  }

  &:active {
    color: #fff;
    background-color: #005d70;
    border-color: #002f38;
    background-image: none;
  }

  &:focus {
    background-color: #007a96;
    color: #fff;
  }
}

button.secondary {
  background-color: white;
  color: #007a96;
  border: 1px solid #007a96;
  height: 40px;
  outline: 0;

  &:hover {
    color: #fff;
    background-color: #007a96;
    border-color: transparent;
    box-shadow: inset -1px -1px 0 0 #0088a3;
  }

  &:active {
    color: #fff;
    background-color: #0088a3;
    border-color: #0088a3;
  }
  
  &:focus {
    background-color: #007a96;
    color: #fff;
  }
}

header {
  border-top: 4px solid $tandf;
}

// ToolTip Styling

.tooltip {
  position: relative;
  // display: inline-block;
  border-bottom: 1px black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 3vh;
  left: 30%;
  margin-left: -60px;
  font-size: 0.8rem;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -41px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}


.tooltip:hover .tooltiptext {
  visibility: visible;
}